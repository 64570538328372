import { Contact } from '@/components/contact';
import { Footer } from '@/components/footer';
import { styled } from '@/stitches.config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { type ReactNode } from 'react';
import { Box } from './box';
import { Badge } from '@/lib/sanity/queries/get-badge';
import { useMeasure } from 'react-use';

type Props = {
  children?: ReactNode;
  title?: string;
  description?: string;
  ogImage?: string;
  showFooter?: boolean;
  hasBackgroundColor?: boolean;
  canonicalToolkitItemUrl?: string;
  enableBadge?: boolean;
  badgeData?: Badge | null;
  contactPersonType?: 'business' | 'careers';
};

export function Layout({
  children,
  title,
  description = "We pioneer through data, design and technology to unravel the world's complexity. We connect people to data to empower insights, reveal new perspectives, and inspire change.",
  ogImage = 'https://cleverfranke.com/api/og',
  showFooter = true,
  hasBackgroundColor = true,
  canonicalToolkitItemUrl,
  badgeData,
  contactPersonType = 'business',
}: Props) {
  const router = useRouter();

  const [footerRef, { height: footerHeight }] = useMeasure<HTMLDivElement>();

  return (
    <>
      <Head>
        {title ? (
          <title key="title">{`${title} | CLEVERºFRANKE`}</title>
        ) : (
          <title key="title">CLEVERºFRANKE | Data Driven Experiences</title>
        )}
        <meta name="description" content={description} />
        {ogImage ? (
          <meta name="ogImage" property="og:image" content={ogImage} />
        ) : null}
        {router.asPath.includes('/services/toolkit') && (
          <link
            rel="canonical"
            href={canonicalToolkitItemUrl}
            key="canonical"
          />
        )}
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <Container
        css={{
          zIndex: 1,
          background: hasBackgroundColor ? '$background' : undefined,
        }}
      >
        <Box
          css={{
            gridArea: 'main',
            minHeight: `calc(100vh - ${footerHeight}px);`,
          }}
        >
          {children}
        </Box>
      </Container>

      {showFooter && (
        <div
          ref={footerRef}
          style={{
            bottom: 0,
            left: 0,
            width: '100%',
          }}
        >
          <Box
            css={{
              background: hasBackgroundColor ? '$foreground' : undefined,
              transition: 'background 0.3s ease-in-out',
            }}
          >
            <Contact
              badgeData={badgeData ?? null}
              contactPersonType={contactPersonType}
            />
            {showFooter && <Footer />}
          </Box>
        </div>
      )}

      {router.isPreview && (
        <Preview href="/api/exit-preview">
          Preview mode is enabled! Click to exit preview mode.
        </Preview>
      )}
    </>
  );
}

const Container = styled('div', {
  display: 'grid',
  gridTemplateAreas: `
    'main'
  `,
  gridTemplateRows: '1fr auto',
  transition: 'background 0.3s ease-in-out',
});

const Preview = styled('a', {
  background: '$blue4',
  color: '$blue11',
  padding: '$3',
  position: 'fixed',
  zIndex: 99999999,
  bottom: '1em',
  left: '1em',
  fontSize: '$2',
  borderRadius: 5,
  lineHeight: '$1',
  border: '1px solid $blue5',
  transition: 'all 150ms ease-out',
  textDecoration: 'none',
  '&:hover': {
    background: '$blue5',
    border: '1px solid $blue6',
  },

  '@bp3': {
    padding: '$5',
  },
});
