import { SVG } from './types';

export function ChevronDown({ strokeWidth = 2, ...props }: SVG) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 9.75L13 16.25L19.5 9.75"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
