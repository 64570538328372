import { SVG } from './types';

export function ExternalLink(props: SVG) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4291 4.0709L21.3688 17.6209L19.3688 17.612L19.4138 7.5004L6.28248 20.6317L4.86827 19.2175L17.9996 6.08619L7.88796 6.1312L7.87906 4.13122L21.4291 4.0709Z"
        fill="currentColor"
      />
    </svg>
  );
}
