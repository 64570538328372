import { Box } from '@/components/box';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';

type IconProps = {
  size?: number;
};

function Clock(props: IconProps) {
  const { size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        d="M12 24C18.6462 24 24 18.6462 24 12C24 5.35385 18.6462 0 12 0C5.35385 0 0 5.35385 0 12C0 18.6462 5.35385 24 12 24ZM12 3.78462C16.5231 3.78462 20.2154 7.47693 20.2154 12C20.2154 16.5231 16.5231 20.2154 12 20.2154V3.78462V3.78462Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ThemeSwitch() {
  const { setTheme, resolvedTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  /**
   * This prevents hydration warnings
   */
  useEffect(() => setMounted(true), []);
  if (!mounted) return null;

  const handleClick = () => {
    if (resolvedTheme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };

  return (
    <Box
      as="button"
      onClick={handleClick}
      css={{
        display: 'block',
        color: '$background',
        transition:
          'transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1), color 0.25s ease-in-out, opacity 0.2s ease-in-out',
        transform:
          resolvedTheme === 'light' ? 'rotate(180deg)' : 'rotate(0deg)',

        '&:hover': {
          opacity: 0.75,
        },
      }}
    >
      <Clock />
    </Box>
  );
}
