import { SVG } from './types';

export function ExternalLinkXsmall(props: SVG) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3L10.9709 9.54557L10.0047 9.54127L10.0265 4.65668L3.68316 11L3 10.3168L9.34332 3.97352L4.45871 3.99526L4.45442 3.02914L11 3Z"
        fill="currentColor"
      />
    </svg>
  );
}
