import { SVG } from './types';

export function ArrowRightXsmall(props: SVG) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82664 3L13 7.12683L8.82664 11.2537L8.07401 10.4926L10.9365 7.66202H1V6.59165H10.9365L8.07401 3.7611L8.82664 3Z"
        fill="currentColor"
      />
    </svg>
  );
}
