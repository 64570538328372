import { SVG } from './types';

export function ArrowLeftSmall(props: SVG) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.27964 9L6.90556 11.5966L5.4993 13.0188L0.423943 8L5.4993 2.98125L6.90556 4.40337L4.27964 7H14.7692V9H4.27964Z"
        fill="currentColor"
      />
    </svg>
  );
}
