import { SVG } from './types';

export function Plus(props: SVG) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" {...props}>
      <path fill="currentColor" d="M4 0H6V10H4z" />
      <path fill="currentColor" d="M0 4H10V6H0z" />
    </svg>
  );
}
