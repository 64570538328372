import { SVG } from './types';

export function LinkedIn(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.41 31.41"
      {...props}
    >
      <path
        d="M23.52 23.92a.51.51 0 00.51-.51v-4.83c0-2.09 0-5.83-4-5.83a3.48 3.48 0 00-3.28 1.54v-.87a.51.51 0 00-.58-.51h-2.41a.51.51 0 00-.51.51v10a.51.51 0 00.51.51h2.41a.51.51 0 00.51-.51v-5.36c.07-.72.38-2.33 1.95-2.33 1.86 0 1.82 2 1.81 2.86v4.82a.51.51 0 00.51.51zM9.95 11.98a2.25 2.25 0 112.22-2.24 2.24 2.24 0 01-2.22 2.24zm1.22 11.94a.55.55 0 00.55-.55v-9.9a.56.56 0 00-.55-.56h-2.4a.56.56 0 00-.55.56v9.9a.55.55 0 00.55.55zM7.08 5.17h17.26a1.9 1.9 0 011.9 1.91v17.26a1.9 1.9 0 01-1.9 1.9H7.08a1.9 1.9 0 01-1.91-1.9V7.08a1.9 1.9 0 011.91-1.91z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}
