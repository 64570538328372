import { SVG } from './types';

export function ArrowRight(props: SVG) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6242 5.28894L24.4222 13L16.6242 20.7111L15.2179 19.2889L20.5665 14H2V12H20.5665L15.2179 6.71106L16.6242 5.28894Z"
        fill="currentColor"
      />
    </svg>
  );
}
