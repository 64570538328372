import { SVG } from './types';

export function Medium(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.81 25.39"
      {...props}
    >
      <path
        d="M6.74 7.67a.85.85 0 00-.27-.7l-2-2.41V4.2h6.2l4.8 10.52L19.69 4.2h5.91v.36L23.89 6.2a.51.51 0 00-.19.48v12a.51.51 0 00.19.48l1.67 1.64v.4H17.2v-.36l1.7-1.64c.17-.17.17-.22.17-.48V8.94L14.26 21.2h-.65L8.02 8.94v8.19a1.07 1.07 0 00.31.93l2.24 2.73v.41H4.2v-.36l2.25-2.73a1.06 1.06 0 00.29-.93z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}
