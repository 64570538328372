import { urlForImage } from '@/lib/sanity/sanity';
import { CSS, keyframes, styled, theme } from '@/stitches.config';
import { motion, useIsomorphicLayoutEffect } from 'framer-motion';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { Box } from '../box';
import shuffle from 'lodash.shuffle';
import { Badge } from '@/lib/sanity/queries/get-badge';

interface AnnouncementBadgeProps {
  css?: CSS;
  invert?: boolean;
  badgeData: Badge;
  animate?: boolean;
  showColors?: boolean;
}

export function AnnouncementBadge({
  css,
  invert,
  badgeData,
  animate = false,
  showColors = false,
}: AnnouncementBadgeProps) {
  const { resolvedTheme } = useTheme();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!badgeData.darkImage || !badgeData.lightImage) return;
    setImageSrc(
      invert
        ? resolvedTheme == 'dark'
          ? urlForImage(badgeData.darkImage).url()
          : urlForImage(badgeData.lightImage).url()
        : resolvedTheme == 'dark'
        ? urlForImage(badgeData.lightImage).url()
        : urlForImage(badgeData.darkImage).url()
    );
  }, [invert, badgeData, resolvedTheme, setImageSrc]);

  return (
    <LinkWrapper
      css={css}
      wiggle={animate}
      href={badgeData.url}
      title={badgeData.label}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5, delay: 1.2 } }}
      exit={{ opacity: 0 }}
    >
      {imageSrc && (
        <Badge
          css={{ opacity: isLoaded ? 1 : 0 }}
          onLoad={() => setIsLoaded(true)}
          src={imageSrc}
          alt={badgeData.label}
        />
      )}
      {animate && showColors && <AnnouncementBadgeTail />}
    </LinkWrapper>
  );
}

function AnnouncementBadgeTail() {
  const [shuffledColors, setShuffledColors] = useState<string[]>([]);

  // This hook makes sure the shuffle only happens on the client
  useIsomorphicLayoutEffect(() => {
    setShuffledColors(
      shuffle([
        theme.colors.red9.value,
        theme.colors.yellow9.value,
        theme.colors.green9.value,
        theme.colors.blue9.value,
        theme.colors.indigo9.value,
        theme.colors.violet9.value,
        theme.colors.crimson9.value,
      ])
    );
  }, []);

  return (
    <>
      {shuffledColors.map((color, index) => (
        <Box
          key={color}
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '999rem',
            backgroundColor: color,
            animation: `${wiggle} cubic-bezier(0.37, 0, 0.63, 1) 15s`,
            animationIterationCount: 'infinite',
            animationDelay: `calc(0.7s * ${index})`,
            zIndex: -1 * index,
          }}
        />
      ))}
    </>
  );
}

const wiggle = keyframes({
  '0%': {
    transform:
      'translate(calc(var(--wiggle-offset) * 0), calc(var(--wiggle-offset) * 0))',
  },
  '20%': {
    transform:
      'translate(calc(var(--wiggle-offset) * 0.938), calc(var(--wiggle-offset) * 0.914))',
  },
  '40%': {
    transform:
      'translate(calc(var(--wiggle-offset) * -0.82), calc(var(--wiggle-offset) * 0.675))',
  },
  '80%': {
    transform:
      'translate(calc(var(--wiggle-offset) * 1.012), calc(var(--wiggle-offset) * -0.829))',
  },
  '100%': {
    transform:
      'translate(calc(var(--wiggle-offset) * 0), calc(var(--wiggle-offset) * 0))',
  },
});

const LinkWrapper = styled(motion.a, {
  borderRadius: '9999rem',
  variants: {
    wiggle: {
      true: {
        '--wiggle-offset': '0.7rem',
        animation: `${wiggle} cubic-bezier(0.37, 0, 0.63, 1) 15s`,
        animationIterationCount: 'infinite',
      },
    },
  },
});

const Badge = styled('img', {
  position: 'relative',
  width: '100%',
  zIndex: 1,
});
