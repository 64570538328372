import { Box } from '@/components/box';
import { Text } from '@/components/text';
import {
  focusStateStyles,
  Underline,
  thinnerUnderlineHoverStyles,
} from '@/components/underline';
import { CSS, styled } from '@/stitches.config';
import Image from 'next/image';
import { Description, Title } from './styled-text';
import thomas from './thomas-clever.jpg';
import julia from './julia-hendriks.jpg';

export function InquiryBlock(props: {
  css?: CSS;
  contactPersonType: 'business' | 'careers';
}) {
  return (
    <Wrapper css={props?.css}>
      <Box
        css={{
          gridArea: 'image',
          marginBottom: '$5',
          width: 68,
          height: 68,
          flexShrink: 0,
          borderRadius: '100%',
          overflow: 'hidden',

          '@bp5': {
            marginBottom: 0,
            width: 180,
            height: 180,
          },
        }}
      >
        <Image
          src={props.contactPersonType === 'business' ? thomas : julia}
          alt={`Photo of ${
            props.contactPersonType === 'business'
              ? 'Thomas Clever'
              : 'Julia Hendriks'
          }`}
          layout="responsive"
          width={480}
          height={480}
          placeholder="blur"
        />
      </Box>

      <Title css={{ gridArea: 'title' }}>Get in touch</Title>

      <Description css={{ gridArea: 'description' }}>
        {props.contactPersonType === 'business'
          ? 'If you’d like to learn more about how we can add value to your business, contact Thomas Clever.'
          : 'If you’d like to learn more about a job opening or internship, contact Julia Hendriks.'}
      </Description>

      <Text
        as="address"
        size={2}
        css={{
          gridArea: 'address',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          mb: '$3',
          '@bp4': { mb: '$6' },
        }}
      >
        <Anchor
          href={`mailto:${
            props.contactPersonType === 'business' ? 'thomas' : 'julia'
          }@cleverfranke.com`}
          css={{
            marginBottom: '$2',
          }}
        >
          {props.contactPersonType === 'business' ? 'thomas' : 'julia'}
          @cleverfranke.com
          <Underline />
        </Anchor>
        <Anchor
          href={
            props.contactPersonType === 'business'
              ? 'tel:+31619552981'
              : 'tel:+31307851926'
          }
        >
          {props.contactPersonType === 'business'
            ? '+31 6 19 55 29 81'
            : '+31 30 785 19 26'}
          <Underline />
        </Anchor>
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateAreas: `'title title' 'image description' '. address'`,
  gridTemplateColumns: 'min-content',
  alignItems: 'center',
  gridTemplateRows: 'repeat(3, min-content)',
  wordBreak: 'break-word',
  columnGap: '$4',
  marginBottom: '$3',

  '@bp4': {
    alignItems: 'flex-start',
    gridTemplateAreas: `'image title' 'image description' 'image address'`,
    columnGap: '$8',
    marginBottom: '$9',
  },
});

const Anchor = styled('a', {
  position: 'relative',
  display: 'inline-flex',
  fontStyle: 'normal',
  color: '$background',
  textDecoration: 'none',
  overflow: 'hidden',

  '&:hover, &:active': {
    [`${Underline} > div `]: thinnerUnderlineHoverStyles,
  },

  '&:focus-visible': {
    ...focusStateStyles,
    outlineOffset: '2px',
    [`${Underline} > div `]: thinnerUnderlineHoverStyles,
  },
});
