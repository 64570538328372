import { Logo } from '@/components/logo';
import { CapsizedText } from '@/components/text';
import { ThemeSwitch } from '@/components/theme-switch';
import { styled } from '@/stitches.config';
import Link from 'next/link';

export function Footer() {
  return (
    <StyledFooter>
      <FooterLogo>
        <Link href="/">
          <a aria-label="Visit the Clever°Franke homepage">
            <StyledLogo />
          </a>
        </Link>
        <CapsizedText css={{ color: '$background', fontSize: '13px' }}>
          Data driven experiences
        </CapsizedText>
      </FooterLogo>

      <ThemeBox>
        <ThemeSwitch />
      </ThemeBox>

      <Terms>
        <CapsizedText size={1} css={{ color: '$background' }}>
          <Anchor href="https://adc-consulting.com/">
            Part of ADC Consulting
          </Anchor>
        </CapsizedText>
        <CapsizedText size={1}>© {`${new Date().getFullYear()} `}</CapsizedText>
        <CapsizedText size={1}>
          <Link href="/privacy-policy" passHref>
            <Anchor aria-label="View our privacy policy">Privacy policy</Anchor>
          </Link>
        </CapsizedText>
      </Terms>
    </StyledFooter>
  );
}

const StyledFooter = styled('footer', {
  display: 'grid',
  gridTemplateAreas: `
  'logo theme'
  'copyright .'
  `,
  gridTemplateColumns: '1fr auto',
  gap: '$5',
  padding: '$4 $4 $5',
  backgroundColor: 'none',

  '@bp3': {
    padding: '$5 $6',
  },

  '@bp4': {
    padding: '$5 $8',
  },

  '@bp5': {
    display: 'flex',
    alignItems: 'center',
  },
});

const FooterLogo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',

  '@bp5': {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
});

const StyledLogo = styled(Logo, {
  color: '$background',
  height: '$4',
  width: '$12',
  '@bp5': {
    marginRight: '$4',
  },
});

const Terms = styled('p', {
  color: '$gray9',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'left',
  gap: '$4',
  mb: 0,

  '@bp5': {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: '1',
    marginRight: 'auto',
    textAlign: 'center',
  },
});

const ThemeBox = styled('div', {
  alignSelf: 'start',
  justifySelf: 'end',
  marginTop: '$5',
  '@bp5': {
    marginTop: '0.1rem',
  },
});

const Anchor = styled('a', {
  border: 0,
  color: 'inherit',
  cursor: 'pointer',
  textDecoration: 'none',
  transition: '0.35s color',
  display: 'block',

  '&:hover': {
    opacity: 0.7,
  },
});
