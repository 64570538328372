import { SVG } from './types';

export function Close({ strokeWidth = 2, ...props }: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <g fill="none">
        <path
          d="M19.5 6.5L6.5 19.5"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
        <path
          d="M6.5 6.5L19.5 19.5"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}
