import { SVG } from './types';

export function Instagram(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.73 30.73"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a" transform="translate(5.17 5.17)">
          <path
            className="prefix__a"
            d="M10.19 1.84c2.72 0 3.05 0 4.12.06a5.45 5.45 0 011.89.35 3.08 3.08 0 011.18.75 3.38 3.38 0 01.76 1.17 5.77 5.77 0 01.35 1.89c.05 1.08.06 1.4.06 4.12s0 3.05-.06 4.12a5.77 5.77 0 01-.35 1.89 3.32 3.32 0 01-1.94 1.94 5.71 5.71 0 01-1.89.35c-1.07.05-1.4.06-4.12.06s-3 0-4.12-.06a5.77 5.77 0 01-1.89-.35A3.38 3.38 0 013 17.38a3.08 3.08 0 01-.76-1.18 5.51 5.51 0 01-.35-1.89c0-1.07-.06-1.4-.06-4.12s0-3 .06-4.12a5.51 5.51 0 01.35-1.89A3.12 3.12 0 013 3a3.12 3.12 0 011.17-.76 5.51 5.51 0 011.9-.34c1.08-.05 1.4-.06 4.12-.06zm0-1.84C7.42 0 7.08 0 6 .06a7.23 7.23 0 00-2.48.48 5.13 5.13 0 00-3 3A7.23 7.23 0 00.06 6C0 7.08 0 7.42 0 10.19s0 3.12.06 4.2a7.3 7.3 0 00.48 2.48 4.86 4.86 0 001.17 1.8 5 5 0 001.81 1.18 7.51 7.51 0 002.48.47c1.09.05 1.43.07 4.2.07s3.12 0 4.2-.07a7.58 7.58 0 002.48-.47 5.28 5.28 0 003-3 7.58 7.58 0 00.47-2.48c.05-1.08.07-1.43.07-4.2s0-3.11-.07-4.2a7.51 7.51 0 00-.47-2.47 5 5 0 00-1.18-1.81A4.86 4.86 0 0016.87.54a7.3 7.3 0 00-2.48-.48C13.31 0 13 0 10.19 0z"
          />
        </clipPath>
        <clipPath id="prefix__b" transform="translate(5.17 5.17)">
          <path
            className="prefix__a"
            d="M10.19 5a5.24 5.24 0 105.24 5.23A5.23 5.23 0 0010.19 5zm0 8.63a3.4 3.4 0 113.4-3.4 3.4 3.4 0 01-3.4 3.36z"
          />
        </clipPath>
        <style>
          {
            '.prefix__a,.prefix__b,.prefix__d{fill:currentColor}.prefix__a{clip-rule:evenodd}.prefix__b{fill-rule:evenodd}'
          }
        </style>
      </defs>
      <path
        className="prefix__b"
        d="M15.36 7.01c2.72 0 3.05 0 4.12.06a5.45 5.45 0 011.89.35 3.08 3.08 0 011.18.75 3.38 3.38 0 01.76 1.17 5.77 5.77 0 01.35 1.89c.05 1.08.06 1.4.06 4.12s0 3.05-.06 4.12a5.77 5.77 0 01-.35 1.89 3.32 3.32 0 01-1.94 1.94 5.71 5.71 0 01-1.89.35c-1.07.05-1.4.06-4.12.06s-3 0-4.12-.06a5.77 5.77 0 01-1.89-.35 3.38 3.38 0 01-1.18-.75 3.08 3.08 0 01-.76-1.18 5.51 5.51 0 01-.35-1.89c0-1.07-.06-1.4-.06-4.12s0-3 .06-4.12a5.51 5.51 0 01.35-1.89 3.12 3.12 0 01.76-1.18 3.12 3.12 0 011.17-.76 5.51 5.51 0 011.9-.34c1.08-.05 1.4-.06 4.12-.06zm0-1.84c-2.77 0-3.11 0-4.19.06a7.23 7.23 0 00-2.48.48 5.13 5.13 0 00-3 3 7.23 7.23 0 00-.46 2.46c-.06 1.08-.06 1.42-.06 4.19s0 3.12.06 4.2a7.3 7.3 0 00.48 2.48 4.86 4.86 0 001.17 1.8 5 5 0 001.81 1.18 7.51 7.51 0 002.48.47c1.09.05 1.43.07 4.2.07s3.12 0 4.2-.07a7.58 7.58 0 002.48-.47 5.28 5.28 0 003-3 7.58 7.58 0 00.47-2.48c.05-1.08.07-1.43.07-4.2s0-3.11-.07-4.2a7.51 7.51 0 00-.47-2.47 5 5 0 00-1.18-1.81 4.86 4.86 0 00-1.83-1.15 7.3 7.3 0 00-2.48-.48c-1.08-.06-1.39-.06-4.2-.06z"
      />
      <path
        className="prefix__b"
        d="M15.36 10.17a5.24 5.24 0 105.24 5.23 5.23 5.23 0 00-5.24-5.23zm0 8.63a3.4 3.4 0 113.4-3.4 3.4 3.4 0 01-3.4 3.36z"
      />
    </svg>
  );
}
