import { Text } from '@/components/text';
import type { CSS } from '@/stitches.config';
interface TextProps {
  css?: CSS;
  children: React.ReactChild;
}

export function Title({ css, children }: TextProps) {
  return (
    <Text
      as="h3"
      size={5}
      css={{
        color: '$background',
        maxWidth: '35ch',
        ...css,
      }}
    >
      {children}
    </Text>
  );
}

export function Description({ css, children }: TextProps) {
  return (
    <Text
      as="p"
      size={2}
      css={{
        maxWidth: '20rem',
        color: '$background',
        ...css,
      }}
    >
      {children}
    </Text>
  );
}
