import { SVG } from './types';

export function BlueSky(props: SVG) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.40174 6.07831C9.46539 7.64428 11.6851 10.8194 12.5001 12.5234C13.3151 10.8195 15.5346 7.64424 17.5984 6.07831C19.0874 4.94837 21.5 4.07409 21.5 6.8561C21.5 7.41171 21.1848 11.5235 21 12.191C20.3575 14.5118 18.0162 15.1037 15.9337 14.7455C19.574 15.3717 20.5 17.4461 18.5001 19.5204C14.7018 23.4601 13.0409 18.532 12.6151 17.2692C12.537 17.0377 12.5005 16.9294 12.5 17.0215C12.4995 16.9294 12.463 17.0377 12.3849 17.2692C11.9593 18.532 10.2984 23.4602 6.4999 19.5204C4.49993 17.4461 5.42596 15.3716 9.06634 14.7455C6.98369 15.1037 4.6424 14.5118 3.99999 12.191C3.81515 11.5234 3.5 7.41164 3.5 6.8561C3.5 4.07409 5.91267 4.94837 7.40163 6.07831H7.40174Z"
        fill="currentColor"
      />
    </svg>
  );
}
