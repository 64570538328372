import { SVG } from './types';

export function ArrowRightSmall(props: SVG) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5007 2.98125L15.5761 8L10.5007 13.0188L9.09444 11.5966L11.7204 9H1.23077V7H11.7204L9.09444 4.40337L10.5007 2.98125Z"
        fill="currentColor"
      />
    </svg>
  );
}
